import { createRef, useEffect, useState } from 'react'
import firebase from '../../firebase'
import Spinner from './Spinner'

type Props = {
  onAuthSuccess: () => void
}

function Login({ onAuthSuccess }: Props) {
  const passwordInput = createRef<HTMLInputElement>()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(login, [])

  function login() {
    setIsLoading(true)
    const password = passwordInput.current?.value
    firebase
      .login(password)
      .finally(() => setIsLoading(false))
      .then(onAuthSuccess)
      .catch(console.error)
  }

  function onEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter') return
    login()
  }

  if (isLoading) return <Spinner />

  return (
    <div style={{ textAlign: 'center', marginTop: '100px', color: 'darkblue' }}>
      <input
        ref={passwordInput}
        onKeyUp={onEnter}
        type="password"
        name="password"
        placeholder="Passwort"
        style={{ fontSize: 'larger', padding: 10, borderBottom: '1px solid darkblue' }}
      />
      {/* <button
        type="submit"
        onClick={login}
        style={{
          fontSize: 'larger',
          padding: 10,
          marginLeft: 5,
          color: 'darkblue',
          background: '#f9fbfc',
          border: 'none',
        }}
      >
        Login
      </button> */}
    </div>
  )
}

export default Login
