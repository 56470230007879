import React, { useState, useRef } from 'react'

import firebase from '../../firebase'
import { IArtwork } from '../../common/interfaces'
import './Artwork.css'

type Props = {
  artwork: IArtwork
  onClose: () => void
}

function ArtworkEditor(props: Props) {
  const [artwork, setArtwork] = useState<IArtwork>(props.artwork)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const imageFileInput = useRef<HTMLInputElement>(null)

  function onInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (isLoading) return
    if (e.target.name === 'year') setArtwork({ ...artwork!, [e.target.name]: Number(e.target.value) })
    else setArtwork({ ...artwork!, [e.target.name]: e.target.value })
  }

  async function onSaveClick(e: React.MouseEvent) {
    if (somethingIsEmpty()) {
      window.alert('Bitte alle Felder ausfüllen')
      return
    }

    setIsLoading(true)
    await firebase.setArtwork(props.artwork.id || null, artwork)
    setIsLoading(false)
    window.location.reload()
  }

  async function onDeleteClick(e: React.MouseEvent) {
    if (!props.artwork.id) return

    const deleteConfirmed = window.confirm('Wirklich löschen?')
    if (!deleteConfirmed) return

    setIsLoading(true)
    await firebase.deleteArtwork(props.artwork.id)
    setIsLoading(false)
    window.location.reload()
  }

  async function onImageFileChange() {
    if (!imageFileInput.current || !imageFileInput.current.files) return

    try {
      const newImage = imageFileInput.current.files[0]

      if (!/image/gi.test(newImage.type)) {
        window.alert('Bitte ein Bild auswählen.')
        return
      }

      // max size is 500 kB
      if (newImage.size > 500 * 1024) {
        window.alert('Bild ist zu groß.')
        return
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        setArtwork({ ...artwork, image: base64String })
      }
      reader.readAsDataURL(newImage)
    } catch (error) {
      console.log(error)
    }
  }

  function somethingHasChanged() {
    const keys = Object.keys(artwork)
    for (const key of keys) {
      if (artwork[key as keyof IArtwork] !== props.artwork[key as keyof IArtwork]) return true
    } 
    return false
  }

  function somethingIsEmpty() {
    const keys = Object.keys(artwork)
    for (const key of keys) {
      if (key === 'description') continue
      if (artwork[key as keyof IArtwork] === '') return true
    }
    return false
  }

  return (
    <div id="modal">
      {somethingHasChanged() && (
        <div id="save-changes-bar">
          <>
            <button onClick={props.onClose}>Zurücksetzen</button>
            <button className="primary" onClick={onSaveClick}>
              Änderungen Speichern
            </button>
          </>
        </div>
      )}
      <section>
        <div id="image-wrapper">
          <input
            type="file"
            name="image"
            accept="image/png, image/jpeg"
            ref={imageFileInput}
            onChange={onImageFileChange}
            style={{ display: 'none' }}
          />
          <img
            src={artwork.image}
            alt={artwork.title}
            onClick={() => imageFileInput.current?.click()}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <article>
          <input
            type="text"
            name="title"
            placeholder="Titel"
            required={true}
            id="title"
            value={artwork.title}
            onChange={onInputChange}
            style={{ fontWeight: 900, fontSize: 'xx-large' }}
          />
          <p style={{ display: 'flex' }}>
            <input
              type="number"
              name="year"
              placeholder="Jahr"
              required={true}
              id="year"
              value={artwork.year}
              onChange={onInputChange}
              style={{ fontStyle: 'italic', display: 'inline', flex: '1' }}
            />
          </p>
          <textarea
            id="description"
            name="description"
            placeholder="Beschreibung"
            required={true}
            rows={6}
            onChange={onInputChange}
            value={artwork.description}
            style={{ width: '100%' }}
          />
        </article>
      </section>
      <footer>
        <button onClick={props.onClose}>schließen</button>
        <button onClick={onDeleteClick} style={{ maxWidth: '200px', color: 'red' }}>
          löschen
        </button>
      </footer>
    </div>
  )
}

export default ArtworkEditor
