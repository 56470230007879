import React from 'react'

import './Portfolio.css'
import PortfolioItem from './PortfolioItem'
import { IArtwork } from '../../common/interfaces'
import newImage from './new.png'

type Props = {
  artworks: IArtwork[]
  onArtworkClick: (index: number) => void
  onNewArtworkClick: () => void
}

function Portfolio({ artworks, onArtworkClick, onNewArtworkClick }: Props) {
  return (
    <>
      <section id="portfolio">
        <div className="portfolio-item" onClick={onNewArtworkClick}>
          <img src={newImage} alt="Neu erstellen" />
        </div>
      </section>
      <section id="portfolio">
        {artworks.map((artwork, index) => (
          <PortfolioItem artwork={artwork} key={artwork.id} onClick={() => onArtworkClick(index)} />
        ))}
      </section>
    </>
  )
}

export default Portfolio
