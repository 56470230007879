import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Login from './components/layout/Login'

ReactDOM.render(
  <React.StrictMode>
    <Login onAuthSuccess={renderApp} />
  </React.StrictMode>,
  document.getElementById('root')
)

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )
}
