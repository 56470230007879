import { useEffect, useState } from 'react'
import { IAbout, IArtwork } from './common/interfaces'
import firebase from './firebase'

export function useAbout(): IAbout | null {
  const [about, setAbout] = useState<IAbout | null>(null)

  useEffect(() => {
    firebase.getAbout().then(setAbout)
  }, [])

  return about
}

export function useArtworks(): IArtwork[] {
  const [artworks, setArtworks] = useState<IArtwork[]>([])

  useEffect(() => {
    firebase.getArtworks().then(setArtworks)
  }, [])

  return artworks
}
