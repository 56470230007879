import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

import { IAbout, IArtwork } from './common/interfaces'

const firebaseConfig = {
  apiKey: 'AIzaSyA_-w8g7z8oKe4nf-TBezwvaekHCVt211k',
  authDomain: 'bernd-heidt-portfolio.firebaseapp.com',
  databaseURL: 'https://bernd-heidt-portfolio.firebaseio.com',
  projectId: 'bernd-heidt-portfolio',
  storageBucket: 'bernd-heidt-portfolio.appspot.com',
  messagingSenderId: '870922803089',
  appId: '1:870922803089:web:68811bcb5c197a47112c30',
}

firebase.initializeApp(firebaseConfig)

const firestore = firebase.firestore()

async function getAbout(): Promise<IAbout> {
  const doc = await firestore.collection('content').doc('about').get()
  const about = doc.data() as IAbout
  return about
}

async function setAbout(about: IAbout) {
  await firestore.collection('content').doc('about').set(about)
}

async function getArtworks(): Promise<IArtwork[]> {
  const snapshot = await firestore.collection('artworks').orderBy('year', 'desc').get()
  const artworks: IArtwork[] = []
  snapshot.forEach((doc) => artworks.push({ id: doc.id, ...doc.data() } as IArtwork))
  return artworks
}

async function setArtwork(artworkId: string | null, artwork: IArtwork) {
  if (!artworkId) return await firestore.collection('artworks').add(artwork)
  return await firestore.collection('artworks').doc(artworkId).set(artwork)
}

async function deleteArtwork(artworkId: string) {
  return await firestore.collection('artworks').doc(artworkId).delete()
}

async function login(password?: string) {
  const EMAIL = 'graef.nico@gmail.com'
  const PASSWORD = password || localStorage.getItem('PASSWORD')
  if (!PASSWORD) throw new Error('No password given or stored.')
  return firebase
    .auth()
    .signInWithEmailAndPassword(EMAIL, PASSWORD)
    .then(() => localStorage.setItem('PASSWORD', PASSWORD))
}

export default {
  getAbout,
  setAbout,
  getArtworks,
  setArtwork,
  deleteArtwork,
  login,
}
