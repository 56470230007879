import React, { useState, useRef } from 'react'

import './intro.css'
import { IAbout } from '../../common/interfaces'
import firebase from '../../firebase'

type Props = {
  about: IAbout
}

function IntroEditor(props: Props) {
  const [about, setAbout] = useState<IAbout>(props.about)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const imageFileInput = useRef<HTMLInputElement>(null)

  function onInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (isLoading) return
    setAbout({ ...about, [e.target.name]: e.target.value })
  }

  async function onSaveClick(e: React.MouseEvent) {
    setIsLoading(true)
    await firebase.setAbout(about)
    setIsLoading(false)
    window.location.reload()
  }

  async function onImageFileChange() {
    if (!imageFileInput.current || !imageFileInput.current.files) return

    try {
      const newImage = imageFileInput.current.files[0]

      if (!/image/gi.test(newImage.type)) {
        window.alert('Bitte ein Bild auswählen.')
        return
      }

      // max size is 500 kB
      if (newImage.size > 500 * 1024) {
        window.alert('Bild ist zu groß.')
        return
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        setAbout({ ...about, image: base64String })
      }
      reader.readAsDataURL(newImage)
    } catch (error) {
      console.log(error)
    }
  }

  function somethingHasChanged() {
    const keys = Object.keys(about)
    for (let key of keys) {
      if (about[key as keyof IAbout] !== props.about[key as keyof IAbout]) return true
    }
    return false
  }

  return (
    <>
      {somethingHasChanged() && (
        <div id="save-changes-bar">
          <button onClick={() => window.location.reload()}>Zurücksetzen</button>
          <button className="primary" onClick={onSaveClick}>
            Änderungen Speichern
          </button>
        </div>
      )}

      <header>
        <input
          type="file"
          name="image"
          accept="image/png, image/jpeg"
          ref={imageFileInput}
          onChange={onImageFileChange}
          style={{ display: 'none' }}
        />
        <img
          src={about.image}
          alt={about.name}
          onClick={() => imageFileInput.current?.click()}
          style={{ cursor: 'pointer' }}
        />
        <p>
          <input
            type="text"
            name="name"
            id="name"
            value={about.name}
            onChange={onInputChange}
            style={{ fontWeight: 'bold' }}
          />
          <textarea name="bio" id="bio" rows={6} onChange={onInputChange} value={about.bio} />
        </p>
        <p id="contact" style={{ display: 'flex', justifyContent: 'center' }}>
          <input
            type="email"
            name="email"
            id="email"
            value={about.email}
            onChange={onInputChange}
            style={{ fontStyle: 'italic', display: 'inline', flex: '1', textAlign: 'center' }}
          />
          <span> // </span>
          <input
            type="text"
            name="address"
            id="address"
            value={about.address}
            onChange={onInputChange}
            style={{ fontStyle: 'italic', display: 'inline', flex: '1', textAlign: 'center' }}
          />
        </p>
      </header>
    </>
  )
}

export default IntroEditor
