import { useState } from 'react'
import './App.css'

import { IArtwork } from './common/interfaces'
import IntroEditor from './components/intro/IntroEditor'
import ArtworkEditor from './components/portfolio/ArtworkEditor'
import Footer from './components/layout/Footer'
import Portfolio from './components/portfolio/Portfolio'
import Triangles from './components/layout/Triangles'
import Spinner from './components/layout/Spinner'
import { useAbout, useArtworks } from './hooks'

function App() {
  const about = useAbout()
  const artworks = useArtworks()
  const [currentArtwork, setCurrentArtwork] = useState<IArtwork | null>(null)
  const [showArtworkEditor, setShowArtworkEditor] = useState<boolean>(false)

  if (artworks.length === 0)
    return (
      <>
        <Spinner />
        <Triangles />
      </>
    )

  function onArtworkClick(index: number) {
    setCurrentArtwork(artworks[index])
    setShowArtworkEditor(true)
  }

  function onNewArtworkClick() {
    const emptyArtwork: IArtwork = {
      title: '',
      description: '',
      year: new Date().getFullYear(),
      // thumbnail: 'https://via.placeholder.com/300x200?text=Bild+hochladen',
      image: 'https://via.placeholder.com/300x200?text=Bild+hochladen',
    }
    setCurrentArtwork(emptyArtwork)
    setShowArtworkEditor(true)
  }

  function onArtworkClose() {
    setCurrentArtwork(null)
    setShowArtworkEditor(false)
  }

  return (
    <>
      {about && <IntroEditor about={about} />}
      {!about && <Spinner />}
      <Portfolio artworks={artworks} onArtworkClick={onArtworkClick} onNewArtworkClick={onNewArtworkClick} />
      {showArtworkEditor && currentArtwork && <ArtworkEditor artwork={currentArtwork} onClose={onArtworkClose} />}
      <Footer />
      <Triangles />
    </>
  )
}

export default App
